import React, { FC, useRef, useState } from "react";
import styled, { css } from "styled-components";
import BasicTemplate from "@templates/BasicTemplate";
import { graphql, Link } from "gatsby";
import { Availability, formatPrice } from "@rendpro/core-ui";
import Contact from "@components/molecules/Contact/Contact";
import InteractiveVisualization from "@components/molecules/InteractiveVisualization/InteractiveVisualization";
import { arrowLeftBoldIcon, downloadIcon } from "@assets/icons";
import { Icon } from "@iconify/react";
import Button from "@components/atoms/Button/Button";
import { cssEaseOutExpo } from "@theme/easing";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Switch from "@components/atoms/Switch/Switch";
import { ChildImageSharp } from "@interface/ChildImageSharp";
import AvailabilityDisplayer from "@components/atoms/AvailabilityDisplayer/AvailabilityDisplayer";
import Table from "@components/atoms/Table/Table";
import { formatNumber } from "@utils/formatNumber";
import { ROOMS } from "@config";
import ScrollTo from "@components/atoms/ScrollTo/ScrollTo";
import { ReactComponent as GroundFloorVector } from "@assets/vectors/ground_floor_vector.svg";
import { ReactComponent as FirstFloorVector } from "@assets/vectors/first_floor_vector.svg";
import { useEvent } from "react-use";

const StyledWrapper = styled.div<{ $isSmaller: boolean }>`
  padding-top: 110px;
  width: 90%;
  max-width: 1300px;
  margin: 70px auto 0;

  ${({ $isSmaller }) =>
    $isSmaller &&
    css`
      max-width: 1100px;
    `};

  @media (max-width: 1520px) {
    max-width: 1100px;

    ${({ $isSmaller }) =>
      $isSmaller &&
      css`
        max-width: 1000px;
      `}
  }

  @media (max-width: 1024px) {
    padding-top: 80px;
  }

  @media (max-width: 460px) {
    margin: 50px auto 0;
  }
`;

const StyledNavigation = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 460px) {
    flex-direction: column;
    align-items: center;
  }
`;

const StyledBackButton = styled(Link)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.foreground};
  text-decoration: none;
  font-weight: 500;

  span {
    display: flex;
    width: 70px;
    height: 70px;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
    font-size: 1.8rem;
    border: 1px solid ${({ theme }) => theme.foreground};
    border-radius: 50%;
    transition: background 0.5s ${cssEaseOutExpo}, color 0.5s ${cssEaseOutExpo};
  }

  :hover span {
    background: ${({ theme }) => theme.foreground};
    color: ${({ theme }) => theme.background};
  }
`;

const StyledDownloadButton = styled(Button)`
  width: 220px;

  @media (max-width: 460px) {
    margin-top: 20px;
  }
`;

const StyledInnerWrapper = styled.div`
  margin-top: 80px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1024px) {
    flex-direction: column;
    position: relative;
    padding-top: 420px;
  }

  @media (max-width: 720px) {
    margin-top: 60px;
  }
`;

const StyledLeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: sticky;
  top: 40px;
  height: fit-content;
  left: 0;

  @media (max-width: 1024px) {
    position: static;
  }
`;

const StyledImagesWrapper = styled.div<{ $isSmaller: boolean }>`
  position: relative;
  width: 30.79vw;
  height: 27.11vw;
  max-height: 520px;
  max-width: 590px;
  margin: 50px 0;

  @media (min-width: 1025px) {
    ${({ $isSmaller }) =>
      $isSmaller &&
      css`
        height: 22vw;
        width: 24vw;
      `}
  }

  @media (max-width: 720px) {
    width: 100%;
    height: 81vw;
  }
`;

const StyledImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
`;

const StyledImageWrapper = styled.div<{ $isActive: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  transition: opacity 0.5s ${cssEaseOutExpo};

  svg {
    width: 100%;
    height: 100%;
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      opacity: 1;
    `}
`;

const StyledRightWrapper = styled.div`
  flex: 1;
  margin-left: 100px;
  max-width: 480px;

  @media (max-width: 1024px) {
    margin: 0 auto;
  }
`;

const StyledNumber = styled.h1`
  font-weight: 700;
  font-size: 7.2rem;
  margin: 20px 0 45px;
`;

const StyledFirstTableWrapper = styled.div`
  margin-bottom: 50px;

  @media (max-width: 1024px) {
    margin-bottom: 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    width: 480px;
  }

  @media (max-width: 720px) {
    width: 100%;
    max-width: 480px;
  }
`;

const StyledTable = styled(Table)``;

const StyledFloorHeadline = styled.h2`
  font-size: 3.6rem;
  font-weight: 500;
  margin: 0 0 20px;
  max-width: 480px;
  text-align: center;

  :last-of-type {
    margin-top: 40px;
  }

  :first-of-type {
    margin-top: 50px;
  }
`;

const StyledButton = styled(Button)`
  margin: 50px auto 0;
  width: 220px;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const HouseTemplate: FC<Props> = ({ data }) => {
  const [currentType, setCurrentType] = useState<0 | 1>(1);
  const [currentFloor, setCurrentFloor] = useState<0 | 1>(0);
  const leftWrapper = useRef<HTMLDivElement>(null);
  const [isSmaller, setSmaller] = useState(false);
  const [lastHeight, setLastHeight] = useState<number>();
  useEvent(
    "resize",
    () => {
      const currHeight =
        leftWrapper.current?.getBoundingClientRect().height + 80;

      if (!isSmaller && currHeight > innerHeight) {
        setSmaller(true);
        setLastHeight(currHeight);
      } else if (isSmaller && lastHeight < innerHeight) {
        setSmaller(false);
      }
    },
    typeof window !== "undefined" && window
  );

  const {
    number,
    availability,
    parcel_area,
    area,
    price,
    garage,
    delivery_date,
  } = data.directus.Houses_by_id;
  const { firstFloorImg, secondFloorImg } = data;

  return (
    <BasicTemplate title={`Dom ${number} | Pałacowa Park`} isSubPage>
      <StyledWrapper $isSmaller={isSmaller}>
        <StyledNavigation>
          <StyledBackButton to="/#offer">
            <span>
              <Icon icon={arrowLeftBoldIcon} />
            </span>
            Powrót
          </StyledBackButton>
          <StyledDownloadButton
            forwardedAs="a"
            href={`/dom${number}.pdf`}
            icon={downloadIcon}
          >
            Pobierz kartę PDF
          </StyledDownloadButton>
        </StyledNavigation>

        <StyledInnerWrapper>
          <StyledLeftWrapper ref={leftWrapper}>
            <Switch
              firstValue="Rzut 2D"
              secondValue="Wizualizacja"
              state={currentType}
              setState={setCurrentType}
              isSmaller={isSmaller}
            />
            <StyledImagesWrapper $isSmaller={isSmaller}>
              <StyledImageWrapper
                $isActive={currentFloor === 0 && currentType === 1}
              >
                <StyledImage
                  image={getImage(
                    firstFloorImg.childImageSharp.gatsbyImageData
                  )}
                  alt=""
                />
              </StyledImageWrapper>

              <StyledImageWrapper
                $isActive={currentFloor === 1 && currentType === 1}
              >
                <StyledImage
                  image={getImage(
                    secondFloorImg.childImageSharp.gatsbyImageData
                  )}
                  alt=""
                />
              </StyledImageWrapper>

              <StyledImageWrapper
                $isActive={currentFloor === 0 && currentType === 0}
              >
                <GroundFloorVector />
              </StyledImageWrapper>

              <StyledImageWrapper
                $isActive={currentFloor === 1 && currentType === 0}
              >
                <FirstFloorVector />
              </StyledImageWrapper>
            </StyledImagesWrapper>
            <Switch
              firstValue="Parter"
              secondValue="Piętro"
              state={currentFloor}
              setState={setCurrentFloor}
              isSmaller={isSmaller}
            />
          </StyledLeftWrapper>
          <StyledRightWrapper>
            <StyledFirstTableWrapper>
              <AvailabilityDisplayer availability={availability} />
              <StyledNumber>Dom {number}</StyledNumber>
              <StyledTable
                withoutCounter
                items={[
                  {
                    name: "Powierzchnia mieszkalna",
                    value: `${formatNumber(area)} m<sup>2</sup>`,
                  },
                  {
                    name: "Powierzchnia działki",
                    value: `${formatNumber(
                      parcel_area as number
                    )} m<sup>2</sup>`,
                  },
                  {
                    name: "Garaż",
                    value: garage,
                  },
                  {
                    name: "Termin oddania",
                    value: delivery_date as string,
                  },
                  availability !== Availability.Unavailable && {
                    name: "Cena",
                    value: formatPrice(price),
                  },
                ]}
              />
            </StyledFirstTableWrapper>
            <StyledFloorHeadline>Parter</StyledFloorHeadline>
            <Table items={ROOMS.firstFloor} />
            <StyledFloorHeadline>Piętro</StyledFloorHeadline>
            <Table items={ROOMS.secondFloor} />
            <StyledButton forwardedAs={ScrollTo} href="#contact">
              Skontaktuj się z nami
            </StyledButton>
          </StyledRightWrapper>
        </StyledInnerWrapper>
      </StyledWrapper>
      <InteractiveVisualization />
      <Contact />
    </BasicTemplate>
  );
};

interface Props {
  data: {
    directus: {
      Houses_by_id: {
        number: number;
        area: number;
        price: number;
        availability: Availability;
        parcel_area: number;
        rooms_count: number;
        delivery_date: string;
        garage: string;
      };
    };
    firstFloorImg: ChildImageSharp;
    secondFloorImg: ChildImageSharp;
  };
}

export const query = graphql`
  query ($id: ID!) {
    directus {
      Houses_by_id(id: $id) {
        number
        area
        price
        availability
        parcel_area
        rooms_count
        delivery_date
        garage
      }
    }

    firstFloorImg: file(name: { eq: "ground_floor" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: BLURRED)
      }
    }

    secondFloorImg: file(name: { eq: "first_floor" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: BLURRED)
      }
    }
  }
`;

export default HouseTemplate;
