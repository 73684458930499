import React, { Dispatch, FC, SetStateAction } from "react";
import styled, { css } from "styled-components";
import { cssEaseInOutCirc, cssEaseOutExpo } from "@theme/easing";

const StyledWrapper = styled.div<{ $isSmaller: boolean }>`
  display: flex;
  overflow: hidden;
  position: relative;
  width: 376px;
  height: 50px;
  border-radius: 30px;
  border: 1px solid ${({ theme }) => theme.foreground};

  @media (min-width: 1025px) {
    ${({ $isSmaller }) =>
      $isSmaller &&
      css`
        height: 40px;
        width: 330px;
      `}
  }

  @media (max-width: 1024px) {
    width: 100%;
    max-width: 376px;
  }
`;

const StyledItem = styled.div<{ $isActive: boolean }>`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
  user-select: none;
  transition: color 1s ${cssEaseOutExpo};
  font-weight: 500;

  ${({ $isActive }) =>
    $isActive &&
    css`
      color: ${({ theme }) => theme.background};
      cursor: default;
    `}
`;

const StyledBackground = styled.span<{ $currentActive: 0 | 1 }>`
  position: absolute;
  pointer-events: none;
  z-index: -1;
  background: ${({ theme }) => theme.primary};
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  border-radius: 30px;
  transform: ${({ $currentActive }) =>
    `translateX(calc(${$currentActive} * 100%))`};
  transition: transform 0.5s ${cssEaseInOutCirc};
`;

const Switch: FC<Props> = ({
  firstValue,
  secondValue,
  state,
  setState,
  isSmaller,
  ...props
}) => {
  return (
    <StyledWrapper $isSmaller={isSmaller} {...props}>
      <StyledItem onClick={() => setState(0)} $isActive={state === 0}>
        {firstValue}
      </StyledItem>
      <StyledItem onClick={() => setState(1)} $isActive={state === 1}>
        {secondValue}
      </StyledItem>
      <StyledBackground $currentActive={state} />
    </StyledWrapper>
  );
};

interface Props {
  firstValue: string;
  secondValue: string;
  state: 0 | 1;
  setState: Dispatch<SetStateAction<0 | 1>>;
  isSmaller: boolean;
}

export default Switch;
